<template>
    <el-container class="changeStudentDialog" v-bind:id="'changeStudentBox'" v-show="dialogVisible">
        <el-header style="height: 40px;">
            <div @mousedown="mousedown">
                <h3>更改学号</h3>
                <div @click.stop="hideDialog()" class="header-btn" style="position: absolute;top: 0px; right: 20px;z-index:1000;">
                    <i class="el-icon-close" @click.stop="hideDialog()"></i>
                </div>
            </div>
        </el-header>
        <el-main>
            <el-form :inline="true" :model='searchForm' ref="searchForm" @submit.native.prevent class="demo-form-inline search-form">
                <el-form-item label="行政班"  prop="hardId" >
                    <el-select v-model="searchForm.hardId" filterable placeholder="请选择">
                        <el-option key="" label="全部" value=""></el-option>
                        <el-option v-for="item in hardClassList" :key="item.id" :label="item.name+'('+item.year+')'" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="查找" >
                    <el-input v-model="searchForm.searchText" placeholder="姓名或学号"  @keyup.enter.native="loadStudentList"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size ="mini" icon="search" @click='loadStudentList'>查询</el-button>
                </el-form-item>
            </el-form>
            <el-table v-loading="loading" :data="tableData" height="400" style="width: 100%" align='center'>
                <el-table-column prop="my_no" label="学号"  align='center' ></el-table-column>
                <el-table-column prop="nick_name" label="姓名"   align='center' ></el-table-column>
                <el-table-column prop="hard_name" label="班级"  align='center' ></el-table-column>
                <el-table-column prop="operation" align='center' label="操作" min-width="120">
                    <template slot-scope='scope'>
                        <el-button type="primary" size="mini" @click='selectStudent(scope.row)'>选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">关 闭</el-button>
            </div>
        </el-main>
    </el-container>
</template>

<script>
    import { mapGetters } from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'changeStudentDialog',
        data(){
            return {
                dialogVisible: false, loading:false, tableData: [], hardClassList: [],
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, hardId: null, searchText: null},
            }
        },
        components:{
        },
        computed:{
        },
      	mounted() {

	    },
        props: {
            licenceId: String,
            oldStudentId: String,
            examId: Number,
        },
        watch: {
            licenceId: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)

                    this.searchForm ={ pageNo: 1, pageSize: 50, totalCount: 0, hardId: null, searchText: null};
                    this.tableData = [];
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
                this.loadHardClassIdList();
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            searchStudent(v){
                if(v && v.length > 3){
                    this.loadStudentList();
                    return;
                }
                if(!v || v.length < 1){
                    this.tableData = [];
                    return;
                }
                console.log("searchStudent", v);
            },
            mousedown(event) {
                this.selectElement = document.getElementById('changeStudentBox');
                var div1 = this.selectElement
                this.selectElement.style.cursor = 'move'
                this.isDowm = true
                var distanceX = event.clientX - this.selectElement.offsetLeft
                var distanceY = event.clientY - this.selectElement.offsetTop
                document.onmousemove = function (ev) {
                    var oevent = ev || event
                    div1.style.left = oevent.clientX - distanceX + 'px'
                    div1.style.top = oevent.clientY - distanceY + 'px'
                }
                document.onmouseup = function () {
                    document.onmousemove = null
                    document.onmouseup = null
                    div1.style.cursor = 'default'
                }
            },
            loadHardClassIdList(){

                let search = { licenceId: this.licenceId};
                let param = { controllerName: 'sclHardClass', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.hardClassList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadStudentList(){
                this.loading = true;
                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.licenceId,
                    pageNo: 1, examId: this.examId, hardId: mUtils.searchText(this.searchForm.hardId), orderBy: 's.my_no'};
                let param = { controllerName: 'scan/scanPre', methodName: '/studentList', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.tableData = data.result;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },

            selectStudent(row){
                this.dialogVisible = false;
                this.$emit('ok', row);

            },
            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadStudentList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadStudentList()
            },
            submitForm(){
                console.log(this.licenceForm);
                let that = this;
                this.$refs.changeStudentDialogForm.validate((valid) => {
                    if(valid){
                        let df = XEUtils.getDateDiff(mUtils.splitDate(this.licenceForm.fromDate), mUtils.splitDate(this.licenceForm.toDate));
                        console.log(df)
                        if(!df.done){
                            this.$message({message: '失效日期不能在生效日期前', type: 'error', duration: 5000});
                            return ;
                        }
                        this.loading = true;
                        let param = { controllerName: 'licence', methodName: '/saveOrUpdate', param: this.licenceForm};

                        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                            console.log('licence', code);
                            if (code == 0) {
                                this.dialogVisible = false;
                                that.$emit('ok');
                            }else if(code == -3){
                                this.$message({message: '该学校已在系统中。。', type: 'error', duration: 5000});
                            }
                            this.loading = false;
                        });
                    }
                });
            },
        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .changeStudentDialog{
        position: absolute;
        z-index:999;
        min-height: 300px;
        min-width: 500px;
        max-height: 650px;

        border: 1px;
        top: 20%;
        left: 20%;
        border-radius: 2px;
        border:1px solid gray;
        overflow: hidden;
        background-color: white;
        div::-webkit-scrollbar {
            display: none;
        }
    }


</style>


