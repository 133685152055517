<template>
  <div class="rightPage" >

    <el-row>
      <el-col :span="20">
        <el-row v-show="tableData"  @click="openViewer">
          <el-col :span="24">
            <canvas id="myCanvas1" width="1000" height="1000"></canvas>
          </el-col>
        </el-row>
        <el-row v-show="tableData"  @click="openViewer">
          <el-col :span="24">
            <canvas id="myCanvas2" width="1000" height="1000"></canvas>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4">
        <div class="right-part" >
          <el-row class="base-info" >
            <el-col :span="24">
              基本信息
            </el-col>

            <el-col :span="24">
              学号：{{scanPre.student_no}}
            </el-col>
            <el-col :span="24">
              姓名：{{scanPre.student_name}}
            </el-col>
            <el-col :span="24" v-if="scanPre.paper_ab!=null">
              AB卷：{{scanPre.paper_ab}}卷
            </el-col>
<!--            <el-col :span="24" style="color: #F6F7FC;white-space:nowrap;">-->
<!--              id：{{scanPre.id}}-->
<!--            </el-col>-->
<!--            <el-col :span="24" style="color: #F6F7FC;white-space:nowrap;">-->
<!--              same id：{{scanPre.same_exam_id}}-->
<!--            </el-col>-->
          </el-row>
          <el-row style="text-align: center" class="base-info">
            <el-col :span="24">
              基本操作
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="success" size="mini" @click="openViewer">查看原卷</el-button>
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="primary" size="mini" @click="openScoring">查看批阅卷</el-button>
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="primary" size="mini" @click="downloadImage">下载</el-button>
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="danger" size="mini" v-if="kgData && kgData.status=='01'" @click="saveZuoBi(true)">学生作弊</el-button>
              <el-button type="danger" size="mini" v-if="kgData && kgData.status=='ZB'" @click="saveZuoBi(false)">取消作弊</el-button>
            </el-col>
            <el-col :span="24" v-show="isOperShow" style="margin-top: 15px;">
              <el-button type="primary" size="mini" @click="openStudent">更换学生</el-button>
            </el-col>

          </el-row>

          <el-row style="text-align: center" class="base-info" v-if="kgList && !isKgEdit">
            <el-col :span="24">
              <el-link type="text"  size="mini" @click="setKgShow(!isKgShow)" >客观题成绩</el-link>
            </el-col>
            <el-col :span="24" style="margin-top: 15px;text-align: center" v-show="isKgShow">
              <el-row>
                <el-col :span="6">序号</el-col>
                <el-col :span="12">作答</el-col>
                <el-col :span="6">得分</el-col>
              </el-row>
              <el-row style="margin-top: 10px" v-for="item in kgList" v-if="item.type=='SingleChoice' || item.type=='MultiChoice' || item.type=='Judge'">
                <el-col :span="4">{{item.num}}</el-col>
                <el-col :span="14" v-if="!'anw' in item">无</el-col>
                <el-col :span="14" v-if="'anw' in item">{{item.anw==""?"无":item.anw}}</el-col>
                <el-col :span="6">{{"userScore" in item?item.userScore: '0'}}</el-col>
              </el-row>
            </el-col>
            <el-col :span="24">
              <el-link type="primary"  size="mini" @click="setKgEdit(true)" >更改分数</el-link>
            </el-col>
          </el-row>
          <el-row style="text-align: center" class="base-info" v-if="kgList && isKgEdit">
            <el-col :span="24">
              客观题成绩更改
            </el-col>
            <el-col :span="24" style="margin-top: 15px;text-align: center">
              <el-row>
                <el-col :span="6">序号</el-col>
                <el-col :span="18">作答</el-col>
              </el-row>
              <el-row style="margin-top: 10px" v-for="item in kgList" v-if="item.type=='SingleChoice' || item.type=='MultiChoice' || item.type=='Judge'">
                <el-col :span="4">{{item.num}}</el-col>
                <el-col :span="18" >
                  <el-input v-model="item.anw"></el-input>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24">
              <el-button type="primary" size="mini" @click="saveKg">保存</el-button>
            </el-col>
          </el-row>
          <el-row style="text-align: center" class="base-info" v-if="showZgList.length > 0">
            <el-col :span="24">
              <el-link type="text"  size="mini" @click="setZgShow(!isZgShow)" >主观题成绩</el-link>
            </el-col>
            <el-col :span="24" style="margin-top: 15px;text-align: center" v-show="isZgShow">
              <el-row>
                <el-col :span="16">题块</el-col>
                <el-col :span="8">得分</el-col>
              </el-row>
              <el-row style="margin-top: 10px" v-for="item in showZgList">
                <el-col :span="16">{{item.exam_seq}}</el-col>
                <el-col :span="8">{{item.score}}</el-col>
              </el-row>
            </el-col>

          </el-row>

        </div>
      </el-col>
    </el-row>

      <el-image-viewer :zIndex="9999" v-if="showViewer && examImageList" :on-close="closeViewer" :url-list="examImageList" />
    <changeStudentDialog ref="changeStudentDialog" :licenceId="scanPre.licence_id" :examId="scanPre.exam_id" :oldStudentId="scanPre.student_id" @ok="changeStudent"></changeStudentDialog>
  </div>

</template>

<script>
  import {mapState, mapMutations, mapGetters} from 'vuex';
  import XEUtils from 'xe-utils'
  import * as simpleApi from "request/simple";
  import * as mUtils from '@/utils/mUtils'
  import { saveAs } from 'file-saver';
  import changeStudentDialog from "./changeStudentDialog";
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
  import {suoXiao} from "../../utils/mUtils";

  export default {
    name: 'rightPage',
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
    },
    props: {
      info: {
        type: Object
      }
    },
    components:{
     ElImageViewer, changeStudentDialog
    },
    watch: {
      info: {
        deep: true, immediate:true,
        handler(n, o){
          console.log(n)
          if(n){
            this.scanPre = n;
            let that = this;
            this.kgScore = 0;
            this.zgScore = 0;
            this.loadKgOne(function () {
              that.loadZgList(function () {
                that.loadExamPages();
              })
            });
          }
        }
      },
    },
    data() {
      return {
        scanPre: null, scanList: [], searchText: '', showViewer: false,
        isKgEdit: false, isKgShow: true, isZgShow: true, kgScore: 0, zgScore: 0, isOperShow: true,
        url: '', id: null, scanFileId: null, examId: null, myStyle: null, kgId: null,
        theDialogVisible: true, loading:false, tableData: [], examImageList: [],
        getPre: null, imgInfo: null, kgData: null, kgList:[], zgList: [], showZgList: [], cardInfo: null, selPoint: null, drawZgList: [],
        searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null},
        percent: {"P2": [[0.37184693, 0.69066076], [0.69066076, 1]], "PD2": [[0.37184693, 0.689799434], [0.689799434, 1]],
          "P3": [[0.2830118, 0.525941187], [0.525941187, 0.761846788], [0.761846788, 1]],
          "P4": [[0.228420257, 0.421617536], [0.421617536, 0.609145881], [0.609145881, 0.798488284], [0.798488284, 1]],
          "P5": [[0.191496103, 0.353653127], [0.353653127, 0.511057601], [0.511057601, 0.669982891], [0.669982891, 0.839300425], [0.839300425, 1]],
          "P6": [[0.164839361, 0.305623739], [0.305623739, 0.442317133], [0.442317133, 0.580319642], [0.580319642, 0.727267769], [0.727267769, 0.866688485], [0.866688485, 1]],
          "P7": [[0.14470408, 0.267333844], [0.267333844, 0.386372342], [0.386372342, 0.506512162], [0.506512162, 0.634504884], [0.634504884, 0.75593756], [0.75593756, 0.871959395], [0.871959395, 1]],
          "P8": [[0.128946919, 0.237625875], [0.237625875, 0.343104625], [0.343104625, 0.449564772], [0.449564772, 0.5630227], [0.5630227, 0.670634921], [0.670634921, 0.773425499], [0.773425499, 0.886883427], [0.886883427, 1]],
          "P9": [[0.116286676, 0.215988456], [0.215988456, 0.312804233], [0.312804233, 0.410505051], [0.410505051, 0.514516595], [0.514516595, 0.613256373], [0.613256373, 0.707647908], [0.707647908, 0.811659452], [0.811659452, 0.915132275], [0.91513227, 1]],
          "P10": [[0.105890185, 0.197974701], [0.197974701, 0.287431234], [0.287431234, 0.377728722], [0.377728722, 0.473772732], [0.473772732, 0.564981254], [0.564981254, 0.652265321], [0.652265321, 0.748309331], [0.748309331, 0.843862784], [0.843862784, 0.922386909], [0.922386909, 1]],
          "P11": [[0.098270031, 0.183727888], [0.183727888, 0.266746878], [0.266746878, 0.350546306], [0.350546306, 0.43967872], [0.43967872, 0.524323621], [0.524323621, 0.590326483], [0.590326483, 0.674458897], [0.674458897, 0.763136056], [0.763136056, 0.841009365], [0.841009365, 0.928004683], [0.928004683, 1]],
          "P12": [[0.091670206, 0.171388704], [0.171388704, 0.24883213], [0.24883213, 0.327003579], [0.327003579, 0.410149851], [0.410149851, 0.489109992], [0.489109992, 0.564672693], [0.564672693, 0.647818965], [0.647818965, 0.730540557], [0.730540557, 0.798519687], [0.798519687, 0.865679791], [0.865679791, 0.932839896], [0.932839896, 1]]
        },
      }
    },
    mounted() {
      let myHeight = document.documentElement.clientHeight ;
      this.myStyle = {height: (myHeight-200) + 'px'};

    },
    destroyed() {

    },
    methods: {

      formatStatus(status){
        return mUtils.examStatus(status);
      },
      setKgEdit(v){
        this.isKgEdit = v;
      },
      loadKgOne(callback){

        let search = { licenceId: this.userInfo.licenceId, studentId: this.scanPre.student_id, examId: this.scanPre.exam_id};
        let param = { controllerName: 'exam/kg', methodName: '/byStudentId',  param: search};
        return simpleApi.post(param).then(({result: {code, data}}) =>  {
          console.log("loadKgOne", data)
          this.kgData = data;
          this.kgList = [];
          this.scanList = [];
          if (code == 0 && data != null) {
            this.kgId = data.id;
            if(data.answerText && data.answerText != null){
              let kg = JSON.parse(data.answerText);
              if(kg.list){
                this.kgList = kg.list;
                console.log("kg.list", kg.list)
                this.kgList = XEUtils.sortBy(this.kgList, ['num'])
              }
            }

          }
          if(callback) callback();
        }).catch((error) => {
          console.log("error", error)
        });
      },
      loadZgList(callback){

        let search = { licenceId: this.userInfo.licenceId, studentId: this.scanPre.student_id,
          examId: this.scanPre.exam_id, orderBy: 'zg.exam_seq, zg.page_no, zg.exam_index'};
        let param = { controllerName: 'exam/zg', methodName: '/list',  param: search, pageNo: 1, pageSize: 300,};
        return simpleApi.post(param).then(({result: {code, data}}) =>  {
          console.log("loadZgList", data)
          this.zgList = [];
          this.showZgList = [];
          if (data && data != null) {
            this.zgList = data.result;
            for (let i=0; i<this.zgList.length; i++){
              let d = this.zgList[i];
              let fd = XEUtils.find(this.showZgList, item => item.exam_seq == d.exam_seq);
              if(!fd) this.showZgList.push(d);
            }
            this.showZgList = this.showZgList.sort((a, b) => {
              let seqA = a.exam_seq, seqB = b.exam_seq;
              let arrA = seqA.split(","), arrB = seqB.split(",");
              let vA = 0, vB = 0;
              if(arrA.length > 1) vA = parseInt(arrA[0]); else vA = parseInt(seqA);
              if(arrB.length > 1) vB = parseInt(arrB[0]); else vB = parseInt(seqB);
              return vA - vB;
            })
          }
          if(callback) callback();
        }).catch((error) => {
          console.log("error", error)
        });
      },
      loadExamPages(){
        let that = this;
        console.log("loadExamPages")
        let search = { licenceId: this.userInfo.licenceId, id: this.scanPre.id};
        let param = { controllerName: 'scan/scanPreResult', methodName: '/getById',  param: search};
        return simpleApi.list(param).then(({result: {code, data}}) =>  {
          console.log("loadExamPages", data)
          this.examImageList = [];this.drawZgList = [];
          if (data) {
            this.tableData = data;
            if(data.ossFullName1 != null){
              this.examImageList.push(data.ossFullName1);
            }
            if(data.ossFullName2 != null){
              this.examImageList.push(data.ossFullName2);
            }
            this.loadCanvas(data, 1, () =>{
              if(data.cutOssFullName2 != null){
                this.loadCanvas(data, 2);
              }
            });
          }
        }).catch((error) => {
          console.log("error", error)
          that.loading = false;
        });
      },
      loadCanvas(d, pageNo, callback){
        let that = this;

        this.$nextTick(() => {
          var canvas = document.getElementById("myCanvas"+pageNo);
          let tt = document.documentElement.clientWidth / 5 *3;
          if(canvas.getContext){
            let ctx = canvas.getContext('2d');
            ctx.font = 'normal 10px Arial,sans-serif '
            let img = new Image();
            img.src = d["cutOssFullName"+pageNo];
            img.crossOrigin = "Anonymous";
            img.onload = function () {
              let scale = 1;
              if(this.width > tt||this.height > tt){
                if(this.width > this.height) scale = tt/this.width;
                if(this.height > this.width) scale = tt/this.height;
              }
              ctx.width = this.width * scale;
              ctx.height = this.height * scale;
              canvas.width = ctx.width;
              canvas.height = ctx.height;
              // console.log("ctx", ctx.width, ctx.height, scale)
              ctx.drawImage(this, 0, 0, ctx.width, ctx.height);
              that.cardInfoAndImage(ctx, d, pageNo, scale);
              if(callback) callback();
            }
          }
        });

      },
      cardInfoAndImage(ctx, d, pageNo, scale){
        try{
          // console.log("cardInfoAndImage", d)
          if(d.cardInfo){
            let cardInfo = JSON.parse(d.cardInfo);
            let index = 0;
            let width = ctx.width;
            let height = ctx.height;
            // let client = document.getElementById("myCanvas"+pageNo).getBoundingClientRect();
            console.log("cardInfo", cardInfo);
            ctx.fillStyle = 'red'
            ctx.strokeStyle = 'red';
            if(cardInfo && cardInfo.length > 0){
              for (let i=0; i<cardInfo[pageNo-1].sections.length; i++){
                let sect = cardInfo[pageNo-1].sections[i];
                if(this.kgData && this.kgData.status == 'ZB'){
                  ctx.font = 'normal bold 56px Arial,sans-serif '
                  ctx.fillText('作弊', width/2-100, height/2-40);
                  ctx.font = 'normal 10px Arial,sans-serif '
                }
                for (let j=0; j<sect.list.length; j++){
                  if(sect.list[j].type == "studentNo"){
                    let pos = sect.list[j].position;
                    let w = pos.width_scale*width;
                    let h = pos.height_scale*height;
                    let x = pos.left_begin*width ;
                    let y = pos.top_begin*height;
                    if("numList" in sect.list[j]){
                      ctx.fillStyle = 'red'
                      let numList = sect.list[j].numList;
                      let numW = w/numList.length;
                      for(let n=0; n<numList.length; n++){
                        ctx.fillText(numList[n], x+5 + numW*n, y+h+10);
                      }
                      ctx.strokeRect(x, y, w, h);
                    }else{
                      ctx.fillStyle = 'blue';
                      ctx.fillText(d.studentNo, x+5 , y+h+25);
                      ctx.fillStyle = 'red';
                    }
                    this.scaleScore(ctx, x, y, w, h);
                  } else if(sect.list[j].type == "Object"){
                    let pos = sect.list[j].position;
                    let branchs = sect.list[j].branch;
                    // console.log('pos', pos, branchs)
                    for (let k=0; k<branchs.length; k++){
                      let branch = branchs[k];
                      ctx.lineWidth = 1;
                      let posWidth = pos.width_scale*width;
                      let posHeight = pos.height_scale*height;
                      let x = pos.left_begin*width + posWidth * branch.left_begin;
                      let y = pos.top_begin*height + posHeight * branch.top_begin;
                      let w = posWidth*branch.width_ratio;
                      let h = posHeight*branch.height_ratio;
                      if("stu_answer" in branch){
                        // console.log('branch', k, pageNo, branch.stu_answer);
                        ctx.font = 'normal 10px Arial,sans-serif '
                        let stu_anw = branch.stu_answer;
                        let numList = branch.ixList;
                        let a_h = h / stu_anw.length;
                        for (let m=0; m<stu_anw.length; m++){
                          let a_x = x+5;
                          let a_y = y+10 + m * a_h;
                          ctx.fillText(numList[m] + "."+stu_anw[m], a_x, a_y)
                        }
                      }
                      ctx.strokeRect(x, y, w, h);
                      // console.log('draw', d.pageNo, branch, pos);
                    }
                    this.drawChoose(ctx, height, width, pos, branchs);
                  }else{
                    let pos = sect.list[j].position;
                    let w = pos.width_scale*width;
                    let h = pos.height_scale*height;
                    let x = pos.left_begin*width ;
                    let y = pos.top_begin*height;
                    if("branch" in sect.list[j]){
                      let branchs = sect.list[j].branch;
                      this.drawZgScore(ctx, branchs, scale, pageNo, index, x, y, w, h);
                    }
                    ctx.strokeRect(x, y, w, h);
                    index = index + 1;
                  }
                }
              }
            }
          }
        }catch (e) {
          console.log("error", e)
        }
      },
      drawZgScore(ctx, branchs, scale, pageNo, exam_index, x, y, w, h){
        // console.log("drawZgScore", branchs, x, y, w, h)
        ctx.font = 'normal 16px Arial,sans-serif '

        for (let i=0; i<branchs.length; i++){
          let branch = branchs[i];
          let ixList = branch.ixList.join(",");
          let numList = branch.numList.join(",");
          let z_x = x -10;
          let z_y = y + 15;
          let z_h_g = h /ixList.length;
          let exitCount = XEUtils.filter(this.drawZgList, item => (item.exam_seq == ixList));
          let blockIndex = exitCount.length;
          let zg = null;
          console.log("ix", numList, ixList, exam_index, (pageNo-1), branch, this.zgList);
          let fidx = XEUtils.findIndexOf(this.zgList, item => (item.exam_seq == ixList && item.page_no == (pageNo-1)));
          if(fidx > -1) {
            let dragFind = XEUtils.find(this.drawZgList, item => { return (item.exam_seq == ixList && item.page_no == (pageNo-1));});
            zg = this.zgList[fidx];
            console.log("exam dragFind", ixList, (pageNo-1), zg, dragFind );
            if(dragFind){
              if(zg.exam_index == dragFind.exam_index){
                let f2zg = XEUtils.find(this.zgList, item => { return item.exam_seq == ixList && item.page_no == (pageNo-1) && item.exam_index != dragFind.exam_index; });
                console.log("exam dragFind2", ixList, zg, f2zg );
                if(f2zg) {
                  // if(this.isLastPoint(zg, f2zg))
                    zg = f2zg
                }
              }
            }
          }
          console.log("exam ", ixList, zg );
          if(zg != null){
            console.log("zg", pageNo, numList, ixList, zg);
            let drawidx = XEUtils.findIndexOf(this.drawZgList, item => { return item.id == zg.id;});
            // if(drawidx > -1) blockIndex ++;
            if(zg.mark_record != null){
              try{
                let dimension = JSON.parse(zg.mark_record);
                console.log("mark_record", dimension);
                let stepScores = [];
                if("scoreInfo" in dimension){
                  let scoreInfo = dimension["scoreInfo"];
                  if("topicData" in scoreInfo){
                    let topicData = scoreInfo["topicData"];
                    if(topicData.length > 1 && drawidx < 0){
                      let ci = parseInt(h/20)-1;
                      for (let di=0; di<topicData.length; di++){
                        let dime = topicData[di];
                        z_y = z_y + 20;
                        ctx.fillText(dime.topicIndex + ":"+ XEUtils.toNumber(dime.score)+"分", z_x, z_y);
                        if (di > 0 && di % ci == 0){
                          z_y = y + 15;
                          z_x = z_x + 80;
                        }
                      }
                    }
                  }
                  if(("setting" in scoreInfo) && ("bySteps" in scoreInfo.setting)) stepScores = scoreInfo.setting.bySteps;
                }
                if("points" in dimension){
                  let points = dimension["points"];
                  let tiScore = 0;
                  if(points.length > 0){
                    console.log("points", blockIndex, ixList, drawidx, points)
                    for (let di=0; di<points.length; di++){
                      let pnt = points[di];
                      // console.log('pnt', x, y, w, h, pnt);
                      if(drawidx > -1){
                        let pre = this.drawZgList[drawidx]
                        let pre_dim = JSON.parse(pre.mark_record);
                        let isExist = false;
                        for (let k=0; k<pre_dim.points.length; k++){
                          let pp = pre_dim.points[k];
                          if(pp.x == pnt.x && pp.y == pnt.y){
                            if("blockIndex" in pp){
                              if(pp.blockIndex != blockIndex) {
                                isExist = true
                                break;
                              }
                            }else{
                              isExist = true
                              break;
                            }
                          }
                        }
                        // console.log("isExist point", blockIndex, ixList, drawidx, isExist, pnt.x, pnt.y)
                        if(isExist) continue
                      }
                      if(("blockIndex" in pnt) && pnt.blockIndex != blockIndex) continue;

                      let iType = pnt.type;
                      let mcX = x + w*mUtils.suoXiao(pnt.scaleX);
                      let mcY = y + h*mUtils.suoXiao(pnt.scaleY);
                      for (let k=1; k<5; k++){
                        if(("scaleX"+k) in pnt) mcX = x + w*mUtils.suoXiao(pnt["scaleX"+k]);
                        if(("scaleY"+k) in pnt) mcY = y + h*mUtils.suoXiao(pnt["scaleY"+k]);
                      }
                      // mcY = mcY + 12
                      let pw = pnt.w * scale;
                      let ph = pnt.h * scale;
                      let endY = y + h;
                      if(iType ==1){ //打勾
                        ctx.font = 'normal 16px Arial,sans-serif '
                        let pmcY = mcY+ph
                        if(pmcY > endY){
                          ctx.beginPath();ctx.moveTo(mcX, (endY-ph)+ph*0.7);ctx.lineTo(mcX+pw*0.3, endY);ctx.lineTo(mcX+pw,(endY-ph));ctx.stroke();
                        }else{
                          ctx.beginPath();ctx.moveTo(mcX, mcY+ph*0.7);ctx.lineTo(mcX+pw*0.3, mcY+ph);ctx.lineTo(mcX+pw,mcY);ctx.stroke();
                        }
                      }
                      if(iType ==11){ //半勾
                        ctx.font = 'normal 16px Arial,sans-serif '
                        ctx.beginPath();ctx.moveTo(mcX, mcY+ph*0.7);ctx.lineTo(mcX+pw*0.3, mcY+ph);ctx.lineTo(mcX+pw,mcY);
                        ctx.moveTo(mcX+pw*0.35, mcY+ph*0.35);ctx.lineTo(mcX+pw*0.55, mcY+ph*0.65);ctx.stroke();
                      }
                      if(iType ==2){ //打叉
                        ctx.font = 'normal 16px Arial,sans-serif '
                        let pmcY = mcY+ph
                        if(pmcY > endY){
                          ctx.beginPath();ctx.moveTo(mcX, endY-ph);ctx.lineTo(mcX+pw, endY);ctx.moveTo(mcX, endY);ctx.lineTo(mcX+pw, endY-ph);ctx.stroke();
                        }else{
                          ctx.beginPath();ctx.moveTo(mcX, mcY);ctx.lineTo(mcX+pw, mcY+ph);ctx.moveTo(mcX, mcY+ph);ctx.lineTo(mcX+pw, mcY);ctx.stroke();
                        }
                      }
                      if(iType ==3){ //
                        // console.log("ellipse", y, h, pnt);
                        ctx.font = 'normal 16px Arial,sans-serif '
                        ctx.beginPath();ctx.ellipse(mcX+pw/2, mcY+ph/2, pw/2, ph/2, 0, 0, Math.PI*2);ctx.stroke();
                      }
                      if(iType ==12){ //下划线
                        ctx.font = 'normal 24px bold Arial,sans-serif '
                        ctx.beginPath();ctx.moveTo(mcX, mcY);ctx.lineTo(mcX+pw, mcY);ctx.stroke();
                      }
                      if(iType ==14){ //椭圆
                        ctx.font = 'normal 16px Arial,sans-serif '
                        ctx.beginPath();ctx.rect(mcX, mcY, pw, ph);ctx.stroke();
                      }
                      if(iType ==4){ //文字
                        ctx.font = 'normal 16px Arial,sans-serif '
                        ctx.fillText(pnt.wenziText, mcX, mcY+ph);
                      }
                      if(iType ==15){ //问号
                        ctx.font = 'normal 28px Arial,sans-serif '
                        ctx.fillText('?', mcX, mcY+ph);
                      }
                      if(iType ==16){ //省略号
                        ctx.font = 'normal 28px Arial,sans-serif '
                        ctx.fillText('...', mcX, mcY+ph);
                      }
                      if(iType ==99){ //踩点
                        ctx.font = 'normal 16px Arial,sans-serif '
                        let iScore = "" +pnt.score
                        if(pnt.score>0) iScore = "+" + pnt.score;
                        if(pnt.score<0) iScore = "-" + pnt.score;
                        ctx.fillText(iScore, mcX, mcY);
                        tiScore = tiScore + XEUtils.toNumber(pnt.score);
                      }
                      if(iType ==100){ //快捷打分
                        ctx.font = 'normal 16px Arial,sans-serif '
                        let iScore = "" +pnt.score
                        if(pnt.score>0) iScore = "+" + pnt.score;
                        if(pnt.score<0) iScore = "-" + pnt.score;
                        ctx.fillText(iScore, mcX+pnt.w-46, mcY+24);
                        tiScore = tiScore + XEUtils.toNumber(pnt.score);
                      }
                    }
                  }
                }
                if(drawidx < 0){
                  ctx.font = 'normal 16px Arial,sans-serif '
                  y=y+15; ctx.fillText("块得分："+zg.score+"，"+(zg.nick_name?("("+zg.nick_name+")"):""), x -10, y);
                  if(stepScores && stepScores.length > 0){
                    for (let ki=0; ki<stepScores.length; ki++){
                      let sps = stepScores[ki]; y=y+25; ctx.fillText(sps.name +"："+sps.score, x-10, y);
                    }
                  }
                }
              }catch (e) {console.log("error", e)}
              this.drawZgList.push(zg);
            }else{
              if(zg.score != null) {
                if(drawidx < 0) {
                  ctx.font = 'normal 16px Arial,sans-serif '
                  ctx.fillText("块得分："+zg.score+"，"+(zg.nick_name?("("+zg.nick_name+")"):""), x -10, z_y);this.drawZgList.push(zg);
                }
              }
            }
          }


        }
      },
      isLastPoint(z1, z2){
        try{
          let dim1 = JSON.parse(z1.mark_record);
          let dim2 = JSON.parse(z2.mark_record);
          let dim1Count = 0, dim2Count = 0
          if("points" in dim1) dim1Count = dim1.points.length;
          if("points" in dim2) dim2Count = dim2.points.length;
          if(dim2Count > dim1Count) return true
          return false

        }catch (e){ console.log("isLastPoint", e)}

      },
      drawChoose(ctx, height, width, pos, branchs){
        let posLeft = pos.left_begin*width;
        let posTop = pos.top_begin*height;
        let posWidth = pos.width_scale*width;
        let posHeight = pos.height_scale*height;
        for(let i=0; i< branchs.length; i++){
          let branch = branchs[i] ;
          let b_t = posHeight * branch.top_begin+posTop;
          let b_l = posWidth * branch.left_begin+posLeft;
          let b_h = posHeight * branch.height_ratio;
          let b_w = posWidth * branch.width_ratio;
          let pos = branch.position;
          let perPre = (branch.subAreaTopicType=="Judge")?"PD":"P";
          let ixList = branch.ixList;
          let ixY = b_t + b_h*2.5/pos.height;
          let tiH = b_h*5/pos.height;
          let percent = this.percent[perPre+branch.chooseCount];
          for (let j=0; j<ixList.length; j++){
            let iY = ixY + j*tiH;
            for (let k=0; k<percent.length; k++){
              let tiX = b_l+b_w*percent[k][0];
              let tiXEnd = b_l+b_w*percent[k][1];
              let tiW = tiXEnd-tiX;
              let tiGe = tiW/4;
              let tiHGe = tiH/4;
              ctx.beginPath();ctx.rect(tiX+tiGe, iY, tiW-tiGe*1.8, tiH-tiHGe*0.8);ctx.stroke();
              // console.log("", j, k, tiX, tiGe, iY, tiH, tiW, tiW-tiGe, tiH-tiHGe )
            }
          }
        }

      },
      scaleScore(ctx, x, y, w, h){
        let that = this;
        XEUtils.arrayEach(this.kgList, (item, key) => {
          that.kgScore = that.kgScore + XEUtils.toNumber(item.userScore);
        });
        if(this.zgList == null || this.zgList.length > 0){
          let trueZgs = [];
          for (let i=0; i<this.zgList.length; i++){
            let d = this.zgList[i];
            let tfind = XEUtils.find(trueZgs, item => {
              return item.exam_seq == d.exam_seq;
            })
            if(!tfind) trueZgs.push(d);
          }
          XEUtils.arrayEach(trueZgs, (item, key) => {
            that.zgScore = that.zgScore + XEUtils.toNumber(item.score);
          });
        }
        ctx.font = 'normal bold 20px Arial,sans-serif '
        let zgScore = XEUtils.toNumber(that.zgScore).toFixed(2), kgScore = XEUtils.toNumber(that.kgScore).toFixed(2);
        let content = "客观题："+kgScore+"分";
        if(this.zgList == null || this.zgList.length > 0) {
          content = content + "， 主观题：" + zgScore + "分"
        }
        content = content + "， 总分：" + (parseFloat(kgScore)+parseFloat(zgScore)).toFixed(2) + "分";
        ctx.fillText(content, x-30, y-35);
      },
      openViewer(){
        console.log("openViewer")
        this.showViewer = true;
      },
      openScoring(){
        let routeData = this.$router.resolve({name: "scoring", query: {e: this.scanPre.exam_id, }});
        window.open(routeData.href, '_blank');
      },
      downloadImage() {
        if(this.tableData){
          if(this.tableData.ossFullName1 != null){
            let canvas = document.getElementById("myCanvas1");
            saveAs(canvas.toDataURL("image/png"), this.scanPre.student_name+ "("+ this.scanPre.student_no + ").png");
            console.log("img1....")
          }
          if(this.tableData.ossFullName2 != null){
            let canvas = document.getElementById("myCanvas2");
            saveAs(canvas.toDataURL("image/png"), this.scanPre.student_name+ "("+ this.scanPre.student_no + ")-2.png");
            console.log("img2....")
          }

        }
      },
      saveZuoBi(isTrue){
        let that = this;
        let s = "将该学生设置为作弊？";
        let status = 'ZB';
        if(!isTrue){
          s = "取消该学生作弊？";
          status = '01';
        }

        this.$confirm(s, '提示', {
          confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
        }).then(() => {
          let p = {id: this.kgId, status: status }
          let param = { controllerName: 'exam/kg', methodName: '/updateStatus', param: p};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              that.$message({ type: 'success', message: '更改成功!'});
              that.kgScore = 0;
              that.zgScore = 0;
              that.loadKgOne(function () {
                that.loadZgList(function () {
                  that.loadExamPages();
                })
              });
            }else{
              that.$message({ type: 'success', message: '更改失败!'});
            }
          });
        }).catch(() => {

        });
      },

      closeViewer(){
        this.showViewer = false;
      },
      setKgShow(v){
        this.isKgShow = v;
      },
      setZgShow(v){
        this.isZgShow = v;
      },
      saveKg(){
        let that = this;
        this.$confirm('此操作将手动更改学生成绩，是否确定?', '提示', {
          confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
        }).then(() => {
          console.log("kgList", that.kgList)
          let p = {id: this.kgId, answerText: JSON.stringify({list: that.kgList} ) }
          let param = { controllerName: 'exam/kg', methodName: '/updateAnswerText', param: p};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              that.$message({ type: 'success', message: '更改成功!'});
              that.isKgEdit = false;
            }else{
              that.$message({ type: 'success', message: '更改失败!'});
            }
          });
        }).catch(() => {

        });
      },
      openStudent(){
        this.$refs.changeStudentDialog.showDialog();
      },
      changeStudent(row){
        let that = this;
        let p = {examId: this.scanPre.exam_id, oldStudentId: this.scanPre.student_id, studentId: row.id,
          studentNo: row.my_no, studentName: row.nick_name, }
        let param = { controllerName: 'scan/scanPreResult', methodName: '/changeStudent', param: p};
        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
          if (code == 0) {
            that.$message({ type: 'success', message: '更改成功!'});
            that.$emit('ok');
          }else{
            that.$message({ type: 'success', message: '更改失败!'});
          }
        });
      }
    }
  }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

  .rightPage {

    .status-error{
      color: red;
    }
    .el-form-item__label {
      font-size: 16px;
      color: black;
    }
    .right-part {
      padding: 10px;
      border: 1px solid rgba(203, 202, 202, 0.71);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .data-row{
        margin-bottom: 10px;
      }
      .student-no-lost {
        text-align: left;
      }
      .base-info {
        text-align: left;
        margin-top: 10px;
        padding: 5px;
        border: 1px solid rgba(203, 202, 202, 0.71);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

  }




</style>
